import { FeatureFlagService } from "@services/feature-flags/FeatureFlags.service";
import { Inject, Service } from "typedi";
import AdminLoginBySiteIdModule from "./modules/AdminLoginBySiteIdModule";
import LoginModule from "./modules/Login.module";
import LoginV3Module from "./modules/LoginV3.module";

@Service()
export default class LoginInteractor {
    public constructor(
        @Inject() private readonly _loginModule: LoginModule,
        @Inject()
        private readonly _adminLoginBySiteIdModule: AdminLoginBySiteIdModule,
        @Inject() private readonly featureFlags: FeatureFlagService,
        @Inject() private readonly loginV3Module: LoginV3Module
    ) {}

    // ---> Actions
    public async login(email: string, password: string, registration = false) {
        return this.loginV3Module.loginV3(email, password, registration);
    }

    public async loginBySiteId(siteId: number): Promise<void> {
        await this._adminLoginBySiteIdModule.login(siteId);
    }
}
