import { Mutations } from "@frameworks/vue/store/enums/StoreEnums";
import VueFramework from "@frameworks/vue/Vue.framework";
import ApiService from "@services/api/Api.service";
import RESPONSE_STATUS from "@services/api/constants/RESPONSE_STATUS";
import IApiBaseResponse from "@services/api/types/IApiBaseResponse";
import IframeService from "@services/iframe/Iframe.service";
import LocalStorageService from "@services/local-storage/LocalStorage.service";
import WindowService from "@services/window/Window.service";
import { Inject, Service } from "typedi";

@Service()
export default class AdminLoginBySiteIdModule {
    public constructor(
        @Inject() private readonly _apiService: ApiService,
        @Inject() private readonly _iFrameService: IframeService,
        @Inject() private readonly _localStorageService: LocalStorageService,
        @Inject() private readonly _vueFramework: VueFramework,
        @Inject() private readonly _windowService: WindowService
    ) {}

    public async login(siteId: number): Promise<void> {
        try {
            // 1. Get login response
            const res = await this._apiService.loginBySiteId(siteId);

            // 2. Handle login response
            this.handleLoginResponse(res);

            // 3. Clear error messages
            this._vueFramework.getStore().commit(Mutations.SET_ERROR, []);

            // 4. Update credentials
            this.updateCredentials(res.data);

            // 5. Update iframe credentials storage
            await this._iFrameService.emitUpdateCredentialsStorage(
                res.data.accessKey,
                res.data.token,
                res.data.userId
            );

            // 6. Handle app version redirect
            // ! Deprecated
            // this.handleAppversion(res.data.appVersion);

            // 7. Normal Redirect
            this._windowService.openByLocation("/order-list/new/1");

            console.log(res);
        } catch (e) {
            console.error("LOGIN_ERROR", e);
            this._vueFramework
                .getStore()
                .commit(Mutations.SET_ERROR, [(<Error>e).message]);

            throw e;
        }
    }

    /**
     * ! Deprecated
     * @param appVersion 
     * @returns 
     */
    private async handleAppversion(appVersion: number): Promise<void> {
        // 1. Validate app version
        if (appVersion === undefined) {
            throw new Error("App version is not defined");
        }

        // 2. If app version is equal to current app version do nothing
        if (appVersion > 0) {
            return;
        }

        // 4. Get redirect path
        const url = this._iFrameService.getIframeModel().getUrlInit();

        // 5. Redirect to old app version
        this._windowService.openByLocation(url);
    }

    private handleLoginResponse(data: IApiBaseResponse): void {
        if (data.status === RESPONSE_STATUS.BAD_REQUEST) {
            throw new Error(data.data.message);
        }

        if (data.status === RESPONSE_STATUS.FORBIDDEN) {
            throw new Error("Unauthorized");
        }
    }

    private updateCredentials(data: any): void {
        // 1. Get and validate access key
        const accessKey = data.accessKey;

        if (!accessKey) {
            throw new Error("Access key not defined");
        }

        // 2. Get and validate user id
        const userId = data.userId;

        if (userId === undefined) {
            throw new Error("User id is not defined");
        }

        // 3. Get and validate token
        const token = data.token;

        if (!token) {
            throw new Error("Token is not defined");
        }

        // 4. Update localstorage with credentials
        this._localStorageService.setUserAccessKey(accessKey);
        this._localStorageService.setUserId(String(userId));
        this._localStorageService.setUserToken(token);
    }
}
